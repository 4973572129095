<template>
	<section class="eval-result">
		<div class="eval-result__body">
			<ValidationObserver ref="form" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(registerEvaluation)">
					<img v-lazy="logUsaid" alt class="logo" />

					<h1>{{ $t("RegisterEvaluation.RiskAssessment") }}</h1>

					<div class="eval-result__container">
						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="register_phone"
								rules="required|numeric"
							>
								<div :class="[classes]">
									<input
										id="phone"
										v-model="dataForm.phoneNumber"
										type="text"
										class="form-control form-group__input"
										autocomplete="phone"
										:maxlength="phoneMaxLength"
										:placeholder="$t('RegisterEvaluation.YourPhone')"
										@keydown="checkValidPhone($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>

						<div class="form-group__input">
							<ValidationProvider
								v-slot="{ classes, errors }"
								name="register_birth"
								rules="required|numeric"
							>
								<div :class="[classes]">
									<input
										id="birth"
										v-model="dataForm.birthYear"
										type="text"
										class="form-control form-group__input"
										autocomplete="birth"
										:maxlength="maxlengthBirth"
										:placeholder="$t('RegisterEvaluation.YourBirth')"
										@keydown="checkValidPhone($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>

						<CButton color="primary" type="submit">{{ $t("RegisterEvaluation.StartNow") }}</CButton>
					</div>

					<i class="eval-des">{{ $t("RegisterEvaluation.Title") }}</i>
				</form>
			</ValidationObserver>
		</div>
	</section>
</template>

<script>
import logUsaid from "@/assets/img/usaid-path.png"
import { REGISTER_EVALUATION } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("riskAssessment")

export default {
	name: "RegisterEvaluation",

	props: {},

	data() {
		return {
			logUsaid,
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			maxlengthBirth: process.env.VUE_APP_BIRTH_YEAR,

			dataForm: {
				phoneNumber: null,
				birthYear: null,
			},
		}
	},

	computed: {},

	watch: {},

	async created() {
		await window.parent.postMessage("register-evaluation", "*")
	},

	methods: {
		...mapActions({ REGISTER_EVALUATION }),

		registerEvaluation() {
			this.REGISTER_EVALUATION(this.dataForm)
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.eval-result {
	@include form;
	@include font-lato;

	color: $color-white;

	&__head {
		background: $color-blue;
		border: 1px solid $color-white;
		border-radius: 12px 12px 0px 0px;
		padding: 20px 20px;
		width: 100%;

		@media (min-width: $xs) {
			padding: 40px 40px;
		}
	}

	&__body {
		text-align: center;
		width: 100%;
		padding: 20px 20px;
		background: transparent;
		border-radius: 0px 0px 12px 12px;

		@media (min-width: $xs) {
			padding: 44px 40px 24px 40px;
		}

		.other {
			@include input-other;
		}

		.btn {
			font-size: 17px;
			padding: 0px 8px;
			font-weight: bold;
			height: 40px;

			@media (min-width: $l) {
				padding: 6px 32px;
			}
		}
	}

	&__container {
		display: grid;
		grid-gap: 6px 20px;
		grid-template-columns: 1fr;
		margin-bottom: 1rem;

		@media (min-width: $xxs) {
			grid-gap: 18px;
			grid-template-columns: repeat(3, 1fr);
		}

		@media (min-width: $l) {
			grid-template-columns: 2fr 2fr 1fr;
		}

		.error-mess {
			color: $color-white;
		}
	}

	input {
		border-radius: 8px;
	}
}

.logo {
	max-width: 270px;

	@media (min-width: $xxs) {
		max-width: 420px;
	}
}

h1 {
	margin-bottom: 1.5rem;
	font-weight: bold;
	font-size: 36px;

	@media (min-width: $xxs) {
		font-size: 50px;
	}
}

.eval-des {
	font-weight: 600;
}

.form-group {
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;

	&__label {
		margin-bottom: 8px;
		font-weight: bold;
		color: $color-gray;
		font-size: 16px;
		align-self: auto;
		flex: 0 0 100%;

		@media (min-width: $xs) {
			flex: 0 0 40%;
		}
	}

	&__input {
		// flex: 0 0 100%;
		font-weight: 500;
		font-size: 16px;
		color: $color-black-pearl;

		// @media (min-width: $xs) {
		// 	flex: 0 0 60%;
		// }

		&:focus {
			@include input-focus;
		}
	}
}

.h-divider {
	@media (min-width: $xs) {
		margin-top: 50px;
	}
}
</style>
