var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"eval-result"},[_c('div',{staticClass:"eval-result__body"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.registerEvaluation)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.logUsaid),expression:"logUsaid"}],staticClass:"logo",attrs:{"alt":""}}),_c('h1',[_vm._v(_vm._s(_vm.$t("RegisterEvaluation.RiskAssessment")))]),_c('div',{staticClass:"eval-result__container"},[_c('div',{staticClass:"form-group__input"},[_c('ValidationProvider',{attrs:{"name":"register_phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:[classes]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataForm.phoneNumber),expression:"dataForm.phoneNumber"}],staticClass:"form-control form-group__input",attrs:{"id":"phone","type":"text","autocomplete":"phone","maxlength":_vm.phoneMaxLength,"placeholder":_vm.$t('RegisterEvaluation.YourPhone')},domProps:{"value":(_vm.dataForm.phoneNumber)},on:{"keydown":function($event){return _vm.checkValidPhone($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataForm, "phoneNumber", $event.target.value)}}}),_c('div',{staticClass:"error-mess"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"form-group__input"},[_c('ValidationProvider',{attrs:{"name":"register_birth","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:[classes]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataForm.birthYear),expression:"dataForm.birthYear"}],staticClass:"form-control form-group__input",attrs:{"id":"birth","type":"text","autocomplete":"birth","maxlength":_vm.maxlengthBirth,"placeholder":_vm.$t('RegisterEvaluation.YourBirth')},domProps:{"value":(_vm.dataForm.birthYear)},on:{"keydown":function($event){return _vm.checkValidPhone($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataForm, "birthYear", $event.target.value)}}}),_c('div',{staticClass:"error-mess"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('CButton',{attrs:{"color":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("RegisterEvaluation.StartNow")))])],1),_c('i',{staticClass:"eval-des"},[_vm._v(_vm._s(_vm.$t("RegisterEvaluation.Title")))])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }